/* Styles for elements */

/* Avatar */
.avatar-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: white;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.1);
}

/* Small loading spinner use in parent with position relative */
.spinner {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  z-index: 100;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  mask: conic-gradient(from 15deg, #0000, #000); /* Standard mask property */
  -webkit-mask: conic-gradient(from 15deg, #0000, #000); /* WebKit browsers */
  animation: spinner 1s infinite steps(12);
}
.spinner,
.spinner:before,
.spinner:after {
  background:
    radial-gradient(closest-side at 50% 12.5%, #bbb 96%, #0000) 50% 0/20% 80%
      repeat-y,
    radial-gradient(closest-side at 12.5% 50%, #bbb 96%, #0000) 0 50%/80% 20%
      repeat-x;
}
.spinner:before,
.spinner:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.spinner:after {
  transform: rotate(60deg);
}
@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

.spinner-big {
  width: 50px;
}

/* Loading dots animation */
.loader {
  width: 80px;
  aspect-ratio: 8;
  --_g: no-repeat radial-gradient(circle closest-side, #888 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: loader 1s infinite linear;
}
@keyframes loader {
  33% {
    background-size:
      calc(100% / 3) 0%,
      calc(100% / 3) 100%,
      calc(100% / 3) 100%;
  }
  50% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 0%,
      calc(100% / 3) 100%;
  }
  66% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 100%,
      calc(100% / 3) 0%;
  }
}

/* Sorting marker */
.sort-direction-marker {
  position: absolute;
  right: 0;
}

/* Tail select add-new feature */
.tail-select .select-dropdown ul li.dropdown-option.new {
  color: #3c82e6;
  background-color: white;
  font-weight: bold;
}
.tail-select .select-dropdown ul li.dropdown-option.new:before {
  opacity: 0.85;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 8v8m4-4H8' /%3E%3C/svg%3E");
}
.tail-select .select-label {
  border: 1px solid rgb(209 213 219);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.375rem;
}
.tail-select.active .select-label {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border-color: rgb(79 70 229);
  outline: 1px solid rgb(79 70 229);
}
.tail-select .select-label::after {
  margin-top: 0.2rem;
}
.tail-select .select-label .label-count {
  font-size: 1rem;
  font-weight: 600;
}
/* Dark mode styles for tail-select */
.dark .tail-select .select-label {
  background-color: rgb(30 41 59);
  border: 1px solid rgb(107 114 128);
  box-shadow: 0 1px 2px 0 rgb(255 255 255 / 0.1);
}
.dark .tail-select.active .select-label {
  box-shadow: 0 1px 2px 0 rgb(255 255 255 / 0.1);
  border-color: rgb(129 140 248);
  outline: 1px solid rgb(129 140 248);
}
.dark .tail-select .select-label::after {
  color: white;
}
.dark .tail-select .select-dropdown {
  background-color: rgb(30 41 59);
}
.dark .tail-select .label-inner {
  color: white;
}
.dark .tail-select .select-label .label-count {
  color: white;
}
.dark .tail-select .dropdown-search {
  border-bottom: 1px solid #4b5563;
}
.dark .tail-select .select-dropdown ul li.optgroup-title {
  color: slategray;
}
.dark .tail-select .select-dropdown ul li.dropdown-option {
  color: white;
}
.dark .tail-select .select-dropdown ul li.dropdown-option.disabled {
  background-color: rgb(30 41 59);
}
.dark .tail-select .select-dropdown ul li.dropdown-option:hover {
  color: black;
  background-color: lightslategray;
}

.dark .tail-select .select-dropdown ul li.dropdown-option.selected {
  color: black;
  background-color: lightslategrey;
}
/* Override tail select disabled icon for more_user/more_location key */
.tail-select.disabled
  .select-dropdown
  ul
  li.dropdown-option[data-key="more_user"]::before,
.tail-select
  .select-dropdown
  ul
  li.dropdown-option.disabled[data-key="more_user"]::before,
.tail-select.disabled
  .select-dropdown
  ul
  li.dropdown-option[data-key="more_location"]::before,
.tail-select
  .select-dropdown
  ul
  li.dropdown-option.disabled[data-key="more_location"]::before {
  background: none;
}

.tail-select .select-dropdown ul li.dropdown-option[data-key="more_user"],
.tail-select .select-dropdown ul li.dropdown-option[data-key="more_location"] {
  cursor: default;
}

/* Custom scroll for menu */
.scroll-left {
  direction: rtl;
}
.scroll-left .content {
  direction: ltr;
}

/* Custom styles for disabled quantiles plot */
.disabled-quantiles-plot .legend .traces:nth-of-type(2n + 1) .legendtoggle {
  display: none;
}
.disabled-quantiles-plot .legend .traces:nth-of-type(2n + 1) .legendtext {
  cursor: default;
}

/* Custom styles for process return ticket buttons */
.pr-buttons-container {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
}
.pr-buttons-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
}
.pr-buttons-container select:focus {
  --tw-ring-color: transparent;
}
