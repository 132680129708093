@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "elements.css";

@font-face {
  font-family: "InterVariable";
  src: url("../../assets/fonts/InterVariable.woff2") format("woff2");
}
@font-face {
  font-family: "HeroIcons";
  src: url("../../assets/fonts/HeroIcons.woff2") format("woff2");
}

/* Prevents rendering tooltips and others before Alpine initialized */
[x-cloak] {
  display: none !important;
}

/* Prevent time ago blocks from disturbing the layout when loading */
[x-time-ago]:empty::before {
  white-space: pre-wrap;
  content: " ";
}
