@tailwind components;

@layer components {
  /* HEADERS */
  /* GENERAL */
  .standard-text {
    @apply text-gray-700 dark:text-gray-200;
  }
  .soft-text {
    @apply text-gray-600 dark:text-gray-300;
  }
  .sharp-text {
    @apply text-gray-900 dark:text-gray-50;
  }
  .slate-text {
    @apply text-slate-500 dark:text-slate-400;
  }
  .gray-text {
    @apply text-gray-500 dark:text-gray-400;
  }

  /* PAGE ELEMENTS */
  .basic-grid {
    @apply pt-8 flex flex-col gap-y-4 gap-x-8 md:grid md:grid-cols-3;
  }
  .page-header {
    @apply flex justify-between items-center pb-5 pt-8;
  }
  .page-header h1 {
    @apply sharp-text text-3xl;
  }
  .horizontal-divider {
    @apply border-b border-gray-300 dark:border-gray-600;
  }
  .info-tile {
    @apply px-6 py-5 bg-white dark:bg-slate-800 shadow-sm dark:shadow-gray-500 border border-gray-300 dark:border-gray-600 sm:rounded-lg;
  }
  .pagination {
    @apply flex items-center justify-center gap-2 py-4;
  }
  .search-input {
    @apply block w-full h-full px-10 border-none placeholder-gray-400 dark:placeholder-gray-500 focus:placeholder-opacity-30 focus:ring-0 sm:text-sm bg-white dark:bg-slate-800;
  }
  .side-column {
    @apply mt-14 md:mt-0 pb-5 flex flex-col justify-start items-start gap-y-5 w-full md:pl-5 md:border-l md:border-gray-300 dark:md:border-gray-600 divide-y divide-gray-200 dark:divide-gray-600;
  }
  .side-column > * {
    @apply pt-5 w-full;
  }

  /* FORMS */
  .form-textarea {
    @apply block w-full focus:ring-indigo-600 dark:focus:ring-indigo-400 focus:border-indigo-600 dark:focus:border-indigo-400 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm dark:shadow-white/10 bg-white dark:bg-slate-800 dark:text-slate-50;
  }
  .form-input {
    @apply form-textarea form-validation max-w-lg disabled:opacity-50;
  }
  /* see https://dev.to/deyemiobaa/adding-custom-validation-to-a-form-with-tailwindcss-1e7d for the validation part */
  .form-validation {
    @apply invalid:[&.touched]:border-red-500 invalid:[&.touched]:text-red-500 invalid:[&.touched]:ring-red-500;
  }
  .form-actions {
    @apply flex py-8 items-center gap-3 justify-end lg:ml-6;
  }
  .form-checkbox {
    @apply focus:border-indigo-600 dark:focus:ring-indigo-400 border-gray-300 dark:border-gray-500 dark:bg-slate-800 rounded-md shadow-sm dark:shadow-white/10 self-center;
  }
  .checkbox-label {
    @apply sharp-text flex flex-none items-center justify-center rounded-md p-3 text-sm font-semibold cursor-pointer focus:outline-none ring-2 ring-inset ring-gray-400 hover:bg-gray-100 dark:hover:bg-white/10;
  }
  /* Does not work in Firefox yet, see https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility */
  .checkbox-label:has(input:checked) {
    @apply ring ring-indigo-600;
  }

  /* FORM TILES */
  .simple-tile {
    @apply flex-1 max-w-xs bg-white dark:bg-slate-800 shadow-sm border border-gray-300 dark:border-gray-500 rounded-md cursor-pointer transition-all duration-100 ease-in-out;
  }
  .address-tile {
    @apply simple-tile flex flex-col overflow-hidden text-xs standard-text hover:border-indigo-600 dark:hover:border-indigo-400 hover:shadow-md dark:shadow-white/10;
  }
  .card-tile {
    @apply p-6 bg-white dark:bg-slate-800 border border-gray-300 dark:border-gray-500 rounded-lg shadow-lg;
  }
  .device-tile {
    @apply address-tile max-w-md;
  }
  .user-tile {
    @apply simple-tile max-w-lg cursor-default;
  }
  .tile-chosen {
    @apply ring-1 ring-indigo-600 dark:ring-indigo-400 border-indigo-600 dark:border-indigo-400 shadow-lg scale-105;
  }
  .suggestions-list {
    @apply absolute z-10 w-full max-w-lg mt-1 p-2 bg-white dark:bg-gray-700 rounded-lg shadow-lg dark:shadow-gray-900;
  }
  .suggestions-list-item {
    @apply flex items-center justify-start p-2 gap-1 cursor-pointer rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600;
  }
  .suggestions-list-item-chosen {
    @apply bg-gray-100 dark:bg-gray-600;
  }

  /* BUTTONS */
  .base-button {
    @apply inline-flex items-center justify-center min-w-fit px-3 md:px-4 py-2 md:py-2.5 text-xs md:text-sm leading-5 font-semibold whitespace-nowrap rounded-md shadow-sm;
  }
  .primary-button {
    @apply base-button border-transparent text-white bg-indigo-600 dark:bg-indigo-500 hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
  }
  .rounded-primary-button {
    @apply primary-button rounded-full text-sm leading-5 font-medium px-2.5 py-0.5;
  }
  .primary-button:disabled {
    @apply bg-gray-400 dark:bg-gray-600 cursor-not-allowed hover:bg-gray-400 dark:hover:bg-gray-600;
  }
  .secondary-button {
    @apply base-button text-indigo-600 dark:text-indigo-400 ring-1 ring-inset ring-indigo-600 dark:ring-indigo-400 bg-transparent hover:bg-indigo-50 dark:hover:bg-indigo-950;
  }
  .soft-button {
    @apply base-button text-gray-700 dark:text-white bg-white dark:bg-white/10 hover:bg-gray-100 dark:hover:bg-white/20 shadow-sm;
  }
  .rounded-button {
    @apply base-button text-gray-700 dark:text-white ring-1 dark:ring-0 ring-inset ring-gray-300 bg-white dark:bg-white/10 hover:bg-gray-50 dark:hover:bg-white/20 rounded-full leading-5 font-medium px-2.5 py-0.5;
  }
  .group-button {
    @apply sharp-text inline-flex items-center justify-center w-1/3 px-1 text-xs font-semibold bg-white dark:bg-white/10 hover:bg-gray-50 dark:hover:bg-white/20 focus:z-10;
  }
  .delete-button {
    @apply base-button text-white bg-rose-600 hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600;
  }
  .event-button {
    @apply base-button text-slate-700 dark:text-slate-200 ring-1 ring-inset ring-slate-300 dark:ring-slate-500 bg-white dark:bg-white/10 hover:bg-gray-50 dark:hover:bg-white/20;
  }
  .download-button {
    @apply flex items-center justify-center h-10 w-12 rounded-lg bg-white dark:bg-white/10 hover:bg-gray-100 dark:hover:bg-white/20;
  }
  .buttons-wrapper {
    @apply ml-4 flex gap-2 items-start justify-end;
  }

  /* LINKS (general links not includes timeline links, table links etc) */
  .primary-link {
    @apply standard-text mx-1 text-sm hover:underline;
  }
  .disabled-link {
    @apply cursor-not-allowed opacity-50;
  }

  /* TOOLTIPS */
  .tooltip {
    @apply absolute z-50 px-2 py-1 text-xs text-white dark:text-gray-900 bg-slate-800 dark:bg-slate-200 rounded-md shadow-lg;
  }
  .tooltip-top {
    @apply tooltip before:w-2 before:h-2 before:rotate-45 before:bg-slate-800 dark:before:bg-slate-200 before:absolute before:z-[-1] before:-bottom-1 before:left-0 before:right-0 before:mx-auto;
  }
  .tooltip-top-align-left {
    @apply tooltip before:w-2 before:h-2 before:rotate-45 before:bg-slate-800 dark:before:bg-slate-200 before:absolute before:z-[-1] before:-bottom-1 before:left-0 before:right-0 before:mx-4 bottom-8 -left-2.5;
  }
  .tooltip-bottom {
    @apply tooltip after:w-2 after:h-2 after:rotate-45 after:bg-slate-800 dark:after:bg-slate-200 after:absolute after:z-[-1] after:-top-1 after:left-0 after:right-0 after:mx-auto;
  }
  .tooltip-left {
    @apply tooltip after:w-2 after:h-2 after:rotate-45 after:bg-slate-800 dark:after:bg-slate-200 after:absolute after:z-[-1] after:-left-1 after:top-0 after:bottom-0 after:my-auto;
  }
  .tooltip-right {
    @apply tooltip after:w-2 after:h-2 after:rotate-45 after:bg-slate-800 dark:after:bg-slate-200 after:absolute after:z-[-1] after:-right-1 after:top-0 after:bottom-0 after:my-auto;
  }

  /* TABLES */
  .gtable {
    @apply border border-gray-300 dark:border-gray-600 rounded-lg overflow-auto shadow dark:shadow-none;
  }
  .gtable-header {
    @apply px-3 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider truncate whitespace-nowrap;
  }
  .gtable-header[x-reorder-by],
  .gtable-header[x-sort-by] {
    @apply cursor-pointer;
  }
  .gtable-header a {
    @apply inline-flex w-full h-full px-3 py-3 justify-between;
  }
  .gtable-cell {
    @apply soft-text px-3 py-2 flex items-center whitespace-nowrap truncate text-sm;
  }
  .gtable-row-link {
    @apply absolute w-full h-full;
  }
  .gtable-inner-link {
    @apply relative items-center justify-center z-10 hover:shadow;
  }
  .gtable-row-header {
    @apply bg-slate-200 dark:bg-slate-700 border-b border-gray-300 dark:border-gray-600 disabled:hover:bg-gray-200;
  }
  .gtable-row {
    @apply relative grid grid-flow-col auto-cols-fr gap-2 text-gray-500 dark:text-gray-400 even:bg-gray-100 dark:even:bg-gray-800;
  }
  .gtable-row:hover {
    @apply bg-slate-200 dark:bg-slate-700;
  }
  .gtable-row-customers {
    @apply gtable-row md:grid-cols-[1fr_1.6fr_0.4fr];
  }
  .gtable-row-devices {
    @apply gtable-row grid-cols-[0.5fr_1fr_1.5fr] md:grid-cols-[0.5fr_1fr_1.8fr_1fr_1.5fr_0.5fr_0.7fr];
  }
  .gtable-row-renewals {
    @apply gtable-row grid-cols-[0.5fr_1fr_1.5fr] md:grid-cols-[0.1fr_0.5fr_0.7fr_0.5fr_0.7fr_0.2fr];
  }
  .gtable-row-device-rules {
    @apply gtable-row grid-cols-[1.3fr_0.5fr_1.2fr];
  }
  .gtable-row-employees {
    @apply gtable-row md:grid-cols-[2fr_2fr_1.6fr_1fr_1.4fr_0.5fr_0.5fr];
  }
  .gtable-row-integrations {
    @apply gtable-row md:grid-cols-[1fr_1.6fr_0.4fr];
  }
  .gtable-row-forecast-reports {
    @apply gtable-row grid-cols-[0.6fr_0.8fr_1.2fr] md:grid-cols-[0.5fr_0.5fr_0.8fr_0.8fr_0.5fr_0.5fr_1fr];
  }
  .gtable-row-loadout-profiles {
    @apply gtable-row grid-cols-[1.5fr_0.5fr] md:grid-cols-[1fr_0.5fr_1fr];
  }
  .gtable-row-locations {
    @apply gtable-row grid-cols-[1.6fr_0.4fr_1fr] md:grid-cols-[0.3fr_1.5fr_0.4fr_1.5fr_0.8fr];
  }
  .gtable-row-motions {
    @apply gtable-row grid-cols-[1.5fr_1.5fr_1fr_1fr_0.5fr] md:grid-cols-[0.5fr_1fr_1fr_1fr_1.5fr_1fr_0.8fr];
  }
  .gtable-row-purchase-invoices {
    @apply gtable-row grid-cols-[1fr_2fr] md:grid-cols-[1fr_2fr_0.5fr];
  }
  .gtable-row-po {
    @apply gtable-row md:grid-cols-[0.4fr];
  }
  .gtable-row-tickets {
    @apply gtable-row grid-cols-[0.4fr_0.5fr_2fr_0.4fr] md:grid-cols-[0.5fr_0.7fr_2fr_0.4fr_2fr];
  }
  .gtable-row-users {
    @apply gtable-row md:grid-cols-[0.7fr_1fr_0.7fr_1.6fr];
  }
  .gtable-row-warranties {
    @apply gtable-row grid-cols-[1.7fr_1fr_0.3fr] md:grid-cols-[1.8fr_0.7fr_0.7fr_0.3fr_1.5fr];
  }

  /* MENU */
  .menu-wrapper {
    @apply flex flex-col flex-1 bg-menu-light dark:bg-menu-dark;
  }
  .menu-item {
    @apply soft-text hover:bg-gray-50 dark:hover:bg-gray-700 hover:sharp-text;
  }
  .menu-item-active {
    @apply text-indigo-600 dark:text-indigo-400 bg-gray-200 dark:bg-gray-900;
  }
  .menu-link {
    @apply flex items-center px-2 py-2 text-base font-medium rounded-md;
  }
  .feed-date {
    @apply pt-2 mx-2 text-sm whitespace-nowrap text-gray-500 w-1/5;
  }

  /* DEVICE TIMELINE */
  .timeline-wrapper {
    @apply flex items-center my-1 p-3;
  }
  .timeline-block {
    @apply flex flex-shrink-0 flex-grow-0 w-60 sm:w-72 xl:w-80 rounded-md shadow-sm;
  }
  .timeline-block-icon {
    @apply flex flex-col flex-shrink-0 justify-center gap-1 items-center bg-slate-400 text-xxs text-white text-nowrap rounded-l-md py-1 md:py-2 px-2 md:px-4;
  }
  .timeline-block-content {
    @apply w-full text-xxs md:text-xs text-gray-400 whitespace-normal text-wrap rounded-r-md border-t border-b border-r border-gray-300 dark:border-gray-600;
  }
  .timeline-block-content-wrapper {
    @apply inline-flex flex-col justify-start gap-1 w-full py-1 md:py-2 px-1 md:px-4 relative;
  }
  .timeline-link {
    @apply flex items-center w-fit gap-1 px-1 -ml-1 border border-transparent rounded-sm standard-text hover:border-gray-300 dark:hover:border-gray-500;
  }

  /* PLOTS */
  .aspect-plot {
    aspect-ratio: 16 / 9;
  }
  /* Responsive card grid for tickets forecast */
  .responsive-card {
    @apply grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 w-full;
  }

  /* DASHBOARD */
  .dashboard-container {
    @apply py-4 grid grid-flow-dense grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8;
  }
  .dashboard-block {
    @apply p-4 min-h-96 bg-white dark:bg-slate-800 rounded shadow-md dark:shadow-none flex flex-col justify-start gap-3;
  }
  .dashboard-span-1 {
    @apply col-span-1;
  }
  .dashboard-span-2 {
    @apply col-span-1 sm:col-span-2;
  }
  .dashboard-span-3 {
    @apply col-span-1 sm:col-span-2 lg:col-span-3;
  }
  .dashboard-span-4 {
    @apply col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4;
  }
  .dashboard-collection-item {
    @apply h-20 px-4 py-2.5 text-sm font-semibold whitespace-nowrap rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-900 bg-white dark:bg-gray-900 hover:bg-gray-50 dark:hover:bg-black;
  }

  /* should be part of Tailwind, but does not work for some reason */
  .aspect-square {
    aspect-ratio: 1 / 1;
  }
}
